<template>
	<div class="add_aupdate_cars">
		<jy-dialog type="formDouble" :title="title" :visible.sync="dialogVisible" custom-class="dialog_form" :width="global.dialogDoubleWidth">
			<div>
				<el-form ref="addUpdateFrom" :rules="formRules" :model="formData" label-width="130px">
					<el-row>
						<el-col :span="12">
							<el-form-item label="司机姓名:" prop="cName">
								<el-input v-model="formData.cName"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="性别:" prop="gender">
								<el-select v-model="formData.gender" placeholder="请选择">
									<el-option v-for="item in genderOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="联系电话:" prop="phone">
								<el-input v-model="formData.phone"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="出生日期:" prop="birth">
								<el-date-picker value-format="yyyy-MM-dd" v-model="formData.birth" type="date" placeholder="选择日期"></el-date-picker>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="身份证号码:" prop="cardId">
								<el-input v-model="formData.cardId"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="身份证地址:" prop="cardIdA">
								<el-input v-model="formData.cardIdA"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="所属机构:" prop="orgId">
								<el-input v-model="orgNa" @focus="selectInstitutionsLine"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="邮箱:" prop="email">
								<el-input v-model="formData.email"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="民族:" prop="nation">
								<el-input v-model="formData.nation"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="籍贯:" prop="orignal">
								<el-input v-model="formData.orignal"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="学历:" prop="edu">
								<el-select v-model="formData.edu" placeholder="请选择">
									<el-option v-for="item in educationList" :key="item.value" :label="item.label" :value="item.value"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="资格证书:" prop="qualCert">
								<el-input v-model="formData.qualCert"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="紧急联系人:" prop="contact">
								<el-input v-model="formData.contact"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="紧急联系人电话:" prop="cPhone">
								<el-input v-model="formData.cPhone"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="婚姻状况:" prop="ma">
								<el-select v-model="formData.ma" placeholder="请选择">
									<el-option v-for="item in marriageList" :key="item.value" :label="item.label" :value="item.value"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="暂住地址:" prop="tAddr">
								<el-input v-model="formData.tAddr"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="司机工号:" prop="wNum">
								<el-input v-model="formData.wNum"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="工龄(年):" prop="wAge">
								<el-input v-model="formData.wAge"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="驾驶证号:" prop="dlNu">
								<el-input v-model="formData.dlNu"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="驾照类型:" prop="dlType">
								<el-select v-model="formData.dlType" placeholder="请选择">
									<el-option v-for="item in dlTypeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="驾驶证领取日期:" prop="dlIssueT">
								<el-date-picker value-format="yyyy-MM-dd" v-model="formData.dlIssueT" type="date" placeholder="选择日期"></el-date-picker>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="驾驶证到期日期:" prop="dlexpireT">
								<el-date-picker value-format="yyyy-MM-dd" v-model="formData.dlexpireT" type="date" placeholder="选择日期"></el-date-picker>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="入职日期:" prop="eDate">
								<el-date-picker value-format="yyyy-MM-dd" v-model="formData.eDate" type="date" placeholder="选择日期"></el-date-picker>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="驾龄(年) :" prop="dAge">
								<el-input v-model="formData.dAge"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="供职状态:" prop="sState">
								<el-select v-model="formData.sState" placeholder="请选择">
									<el-option v-for="item in serviceStateList" :key="item.value" :label="item.label" :value="item.value"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="离职日期:" prop="rDate">
								<el-date-picker value-format="yyyy-MM-dd" v-model="formData.rDate" type="date" placeholder="选择日期" size="mini"></el-date-picker>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="考勤卡号 :" prop="cwaNo">
								<el-input v-model="formData.cwaNo"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="合同开始时间:" prop="contractBeT">
								<el-date-picker value-format="yyyy-MM-dd" v-model="formData.contractBeT" type="date" placeholder="选择日期"></el-date-picker>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="合同结束时间 :" prop="contractExT">
								<el-date-picker value-format="yyyy-MM-dd" v-model="formData.contractExT" type="date" placeholder="选择日期"></el-date-picker>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="24">
							<el-form-item label="备注:" prop="remark">
								<el-input v-model="formData.remark" type="textarea" :rows="5"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="cancel" v-if="!disabled">取 消</el-button>
				<el-button type="primary" @click="save" v-if="!disabled">确 定</el-button>
			</div>
		</jy-dialog>
		<institutions-tree ref="institutions" @addInstitutions="addInstitutions" title="选择机构"></institutions-tree>
	</div>
</template>
<script type="text/javascript">
	const serviceStateList = [
        {
			label: "在职",
			value: "1",
		},
		{
			label: "离职",
			value: "0",
		},
	];
	//0-未婚 1-已婚 ,
	const marriageList = [
		{
			label: "未婚",
			value: "0",
		},
		{
			label: "已婚",
			value: "1",
		},
	];
	//0-女 1-男 ,
	const genderOptions = [
		{
			label: "女",
			value: "0",
		},
		{
			label: "男",
			value: "1",
		},
	];
	//0-A1,1-A2,2-A3,3-B1,4-B2,5-C1,6-C2,7-C3,8-C4
	const dlTypeList = [
		{
			label: "A1",
			value: "0",
		},
		{
			label: "A2",
			value: "1",
		},
		{
			label: "A3",
			value: "2",
		},
		{
			label: "B1",
			value: "3",
		},
		{
			label: "B2",
			value: "4",
		},
		{
			label: "C1",
			value: "5",
		},
		{
			label: "C2",
			value: "6",
		},
		{
			label: "C3",
			value: "7",
		},
		{
			label: "C4",
			value: "8",
		},
	];
	//学历(0.博士,1.硕士,2.本科,3.专科,4.高中,5.初中,6.小学)
	const educationList = [
		{
			label: "博士",
			value: "博士",
		},
		{
			label: "硕士",
			value: "硕士",
		},
		{
			label: "本科",
			value: "本科",
		},
		{
			label: "专科",
			value: "专科",
		},
		{
			label: "高中",
			value: "高中",
		},
		{
			label: "初中",
			value: "初中",
		},
		{
			label: "小学",
			value: "小学",
		},
	];
	const formRules = {
		cardId: [
			{ required: true, message: "请输入身份证号码", trigger: "blur" },
			{
				min: 18,
				max: 18,
				message: "身份证号码格式不正确",
				trigger: "blur",
			},
		],
		cName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
		orgId: [{ required: true, message: "请选择机构", trigger: "change" }],
		dlNu: [{ required: true, message: "请输入驾驶证号", trigger: "blur" }],
		phone: [
			{ required: true, message: "请输入手机号码", trigger: "blur" },
			{
				min: 11,
				max: 11,
				message: "手机号码格式不正确",
				trigger: "blur",
			},
		],
		sState: [
			{ required: true, message: "请选择供职状态", trigger: "change" },
		],
		eDate: [
			{ required: true, message: "请选择入职日期", trigger: "change" },
		],
		wNum: [{ required: true, message: "请输入司机工号", trigger: "blur" }],
		cwaNo: [{ required: true, message: "请输入考勤卡号", trigger: "blur" }]
	};

	import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
	export default {
		data() {
			return {
				type: "add",
				dialogVisible: false,
				disabled: false,
				formData: {
					cName: "",
					gender: "",
					nation: "",
					orignal: "",
					phone: "",
					birth: "",
					cardIdA: "",
					cardId: "",
					tAddr: "",
					email: "",
					edu: "",
					qualCert: "",
					contact: "",
					cPhone: "",
					ma: "",
					orgId: "",
					wNum: "",
					wAge: "",
					dlNu: "",
					dlType: "",
					dlIssueT: "",
					dlexpireT: "",
					vehicleId: "",
					dAge: "",
					eDate: "",
					workNature: "",
					sState: "",
					rDate: "",
					remark: "",
					cwaNo: "",
					contractExT: "",
					contractBeT: ""
				},
				serviceStateList,
				dlTypeList,
				marriageList,
				educationList,
				formRules,
				genderOptions,
				orgNa: "",
			};
		},
		computed: {
			title: function () {
				let t = "添加司机";
				if (this.type == "update") {
					t = "编辑司机信息";
				}
				return t;
			},
		},
		components: {
			institutionsTree,
		},
		watch: {
			dialogVisible: function (val) {
				if (!val) {
					this.$refs["addUpdateFrom"].resetFields();
					this.formData = {
						cName: "",
						gender: "",
						nation: "",
						orignal: "",
						phone: "",
						birth: "",
						cardIdA: "",
						cardId: "",
						tAddr: "",
						email: "",
						edu: "",
						qualCert: "",
						contact: "",
						cPhone: "",
						ma: "",
						orgId: "",
						wNum: "",
						wAge: "",
						dlNu: "",
						dlType: "",
						dlIssueT: "",
						dlexpireT: "",
						vehicleId: "",
						dAge: "",
						eDate: "",
						sState: "",
						rDate: "",
						remark: "",
						cwaNo: "",
						contractExT: "",
						contractBeT: ""
					};
					this.orgNa = "";
				}
			},
		},
		methods: {
			init(option, type) {
				this.type = type || "add";
				if (option) {
					this.formData = {
						...this.formData,
						...option,
					};
					this.orgNa = option.orgNa;
				}
				this.dialogVisible = true;
			},
			save() {
				this.$refs["addUpdateFrom"].validate((valid) => {
					if (valid) {
						console.log(this.formData);

						// if (this.formData.sState == "0") {
						// 	this.$confirm(
						// 		"您将该司机的供职状态标注为“离职”,确认提交后，请在日常排班页面确认是否有该司机的排班信息，如有，请替换该班次的司机, 是否继续?",
						// 		"提示",
						// 		{
						// 			confirmButtonText: "确定",
						// 			cancelButtonText: "取消",
						// 			type: "warning",
						// 		}
						// 	).then(() => {
						// 		let url = "/base/driver/add";
						// 		if (this.type == "update") {
						// 			url = "/base/driver/update";
						// 		}
						// 		let params = {
						// 			...this.formData,
						// 		};
						// 		this.$http.post(url, params).then((res) => {
						// 			this.$emit("finished");
						// 			this.$message({
						// 				type: "success",
						// 				message: res.msg,
						// 			});
						// 			this.dialogVisible = false;
						// 		});
						// 	});
						// } else {
							let url = "/base/driver/add";
							if (this.type == "update") {
								url = "/base/driver/update";
							}
							let params = {
								...this.formData,
							};
							console.log(params)
							this.$http.post(url, params).then((res) => {
								this.$emit("finished");
								this.$message({
									type: "success",
									message: res.msg,
								});
								this.dialogVisible = false;
							});
						// }
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
			cancel() {
				this.dialogVisible = false;
			},
			// 选择
			selectInstitutionsLine() {
				this.$refs.institutions.init();
			},
			addInstitutions(data) {
				console.log(data);
				this.formData.orgId = data.orgId;
				this.orgNa = data.orgNa;
			},
		},
	};
</script>
