<template>
    <div class="driverInformation">
        <jy-query ref="checkForm" :model="formInline">
            <jy-query-item label="司机姓名:" prop="cName">
                <el-input v-model="formInline.cName" placeholder="请输入"></el-input>
            </jy-query-item>
            <jy-query-item label="司机工号:" prop="wNum">
                <el-input v-model="formInline.wNum" placeholder="请输入"></el-input>
            </jy-query-item>
            <jy-query-item label="所属机构:" prop="orgNa">
                <div @click="selectInstitutions">
                    <el-input placeholder="请选择" v-model="formInline.orgNa"></el-input>
                </div>
            </jy-query-item>
            <jy-query-item label="联系电话:" prop="phone">
                <el-input v-model="formInline.phone" placeholder="请输入"></el-input>
            </jy-query-item>
            <!-- <jy-query-item label="所属线路:" prop="routeId">
                <el-select v-model="formInline.routeId" placeholder="请选择线路">
                    <el-option v-for="item in routeOptions" :key="item.routeId" :label="item.name" :value="item.routeId">
                    </el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="所属车辆:" prop="vehicleName">
                <div @click="addcar">
                    <el-input v-model="formInline.vehicleName" placeholder="请输入"></el-input>
                </div>
            </jy-query-item> -->
            <template slot="rightCol">
                <el-button type="primary" @click="oncheck" v-if="btnexist('scheduleBasicDriverList')">查询</el-button>
                <el-button type="primary" @click="resetForm('checkForm')" v-if="btnexist('scheduleBasicDriverReset')">重置</el-button>
                <el-row>
                    <el-button type="primary" @click="add" v-if="btnexist('scheduleBasicDriverInsert')">新增</el-button>
                    <!-- <el-button type="primary" @click="batchdelete">批量删除</el-button> -->
                    <el-button type="primary" @click="exportList" v-if="btnexist('scheduleBasicDriverExport')">导出</el-button>
                    <el-button type="primary" @click="importFun" v-if="btnexist('scheduleBasicDriverImport')">导入</el-button>
                </el-row>
            </template>
        </jy-query>
        <jy-table ref="jyTable" :data="lists" @select-all="selectallchange" @select="selectchange" :row-style="setRowStyle">
            <jy-table-column type="index" width="60" label="序号" fixed="left"></jy-table-column>
            <jy-table-column type="selection" width="40" fixed="left"></jy-table-column>
            <jy-table-column prop="cName" label="司机姓名" min-width="100" fixed="left"></jy-table-column>
            <jy-table-column prop="carPlateNums" min-width="200" label="已关联车辆"></jy-table-column>
            <jy-table-column prop="orgNa" label="所属机构" min-width="200"></jy-table-column>
            <jy-table-column prop="wNum" label="司机工号" min-width="100"></jy-table-column>
            <jy-table-column prop="eDate" label="入职时间" min-width="100"></jy-table-column>
            <jy-table-column prop="rDate" label="离职日期" min-width="100"></jy-table-column>
            <jy-table-column prop="wAge" label="工龄（年）" min-width="100"></jy-table-column>
            <jy-table-column prop="dAge" label="驾龄（年）" min-width="100"></jy-table-column>
            <jy-table-column label="性别" min-width="100">
                <template slot-scope="scope">{{scope.row.gender|genderType}}</template>
            </jy-table-column>
            <jy-table-column prop="birth" label="出生日期" min-width="100"></jy-table-column>
            <jy-table-column prop="nation" label="民族" min-width="100"></jy-table-column>
            <jy-table-column prop="orignal" label="籍贯" min-width="100"></jy-table-column>
            <jy-table-column prop="cardId" label="身份证号" min-width="180"></jy-table-column>
            <jy-table-column prop="cardIdA" label="身份证地址" min-width="100"></jy-table-column>
            <jy-table-column prop="tAddr" label="暂住地址" min-width="100"></jy-table-column>
            <jy-table-column prop="phone" label="联系电话" min-width="140"></jy-table-column>
            <jy-table-column prop="contact" label="紧急联系人" min-width="100"></jy-table-column>
            <jy-table-column prop="cPhone" label="紧急联系人电话" min-width="100"></jy-table-column>
            <jy-table-column prop="email" label="邮箱" min-width="100"></jy-table-column>
            <jy-table-column label="学历" min-width="100">
                <template slot-scope="scope">{{scope.row.edu}}</template>
            </jy-table-column>
            <jy-table-column label="婚姻状况" min-width="100">
                <template slot-scope="scope">{{scope.row.ma|maType}}</template>
            </jy-table-column>
            <jy-table-column prop="dlNu" label="驾驶证号" min-width="100"></jy-table-column>
            <jy-table-column prop="dlIssueT" label="驾驶证领证日期" min-width="100"></jy-table-column>
            <jy-table-column prop="dlexpireT" label="驾驶证到期时间" min-width="100"></jy-table-column>
            <jy-table-column label="驾照类型" min-width="100">
                <template slot-scope="scope">{{scope.row.dlType|dlType}}</template>
            </jy-table-column>
            <jy-table-column label="供职状态" min-width="100">
                <template slot-scope="scope">{{scope.row.sState|serType}}</template>
            </jy-table-column>
            <jy-table-column prop="qualCert" label="资格证书" min-width="100"></jy-table-column>
            <jy-table-column prop="remark" label="备注" min-width="200" class-name="align_left"></jy-table-column>
            <jy-table-column prop="cwaNo" label="考勤卡号" min-width="100"></jy-table-column>
            <jy-table-column prop="contractBeT" label="合同开始时间" min-width="100"></jy-table-column>
            <jy-table-column prop="contractExT" label="合同结束时间" min-width="100"></jy-table-column>
            <jy-operate v-bind:list="operateList" width="200"></jy-operate>
        </jy-table>
        <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total"></jy-pagination>
        <!-- <add-dialog ref="dialog" @updateList="checkCar"></add-dialog> -->
        <add-update ref="addUpdate" @finished="getlist"></add-update>
        <institutions-tree ref="institutions" @addInstitutions="addInstitutions" url="/waybillOperate/getTree" title="选择所属机构" :defaultProps="props"></institutions-tree>
        <jy-import ref="jyImport" :headers="uploadHeaders" :action="action" @downloadTemplate="downloadTemplate" @getElxData="getElxData" @confirm="confirm" :successLength="correctCount" :errList="listError" :status.sync="importStatus"></jy-import>
    </div>
</template>
<script>
// import adddialog from '@/components/pages/admin/basicInformation/driverInformation/addcar'
import addUpdate from '@/components/pages/admin/basicInformation/driverInformation/addUpdate'
import institutionsTree from '@/components/pages/admin/common/dialog-tree/institutions_tree'
import {btnMixins} from '@/common/js/button.mixin'
export default {
    data() {
        return {
            formInline: {
                orgId: '',
                orgNa: '',
                cName: '',
                wNum: '',
                phone: ''
                // vehicleId: '',
                // vehicleName: ''
            },
            props: {
                children: 'children',
                label: 'text',
                value: 'id'
            },
            lists: [],
            // 批量删除
            batchdeleteList: [],
            // 分页
            pageIndex: 1,
            total: 100,
            pageSize: 10,
            operateList: [],

            routeOptions:[],

            //导入
            uploadHeaders: {
                token: this.$util.getStor('token'),
            },
            action: '/IntelligentArrangeSystem/base/driver/importVehicleCach',
            importId: '',
            listError: [],
            correctCount: '',
            errorCount: '',

            importStatus: 1,

            btnMenuId:'scheduleBasicDriver',
        }
    },
    filters: {
        genderType(val) {
            if (val == '0') {
                return '女'
            } else if (val == '1') {
                return '男'
            }
        },
        maType(val) {
            if (val == '0') {
                return '未婚'
            } else if (val == '1') {
                return '已婚'
            }
        },
        dlType(val) {
            let v = ''
            switch (val) {
                case '0':
                    v = 'A1'
                    break
                case '1':
                    v = 'A2'
                    break
                case '2':
                    v = 'A3'
                    break
                case '3':
                    v = 'B1'
                    break
                case '4':
                    v = 'B2'
                    break
                case '5':
                    v = 'C1'
                    break
                case '6':
                    v = 'C2'
                    break
                case '7':
                    v = 'C3'
                    break
                case '8':
                    v = 'C4'
                    break
            }
            return v
        },
        serType(val) {
            if (val == '0') {
                return '离职'
            } else if (val == '1') {
                return '在职'
            }
        }
    },
    mixins:[btnMixins],
    components: {
        // 'add-dialog': adddialog,
        addUpdate,
        institutionsTree
    },
    created() {
        this.getlist()
        this.setOperateList()
    },
    activated(){
        // this.getRouteList()
    },
    methods: {
        getRouteList(){
            let url = '/baseinforoute/queryCurrentAuthorityRoute'
            this.$http.post(url).then(({detail})=>{
                this.routeOptions = detail
            })
        },
        setOperateList() {
            let l = [{
                    name: '修改',
                    fun: this.update,
                    isShow:()=>{
                        return this.btnexist('scheduleBasicDriverUpdate')
                    },
                },
                {
                    name: '删除',
                    fun: this.deleteRow,
                    isShow:()=>{
                        return this.btnexist('scheduleBasicDriverDelete')
                    },
                },
                {
                    name: '班次推送',
                    fun: this.pushShift,
                    isShow:()=>{
                        return this.btnexist('scheduleBasicDriverPush')
                    },
                },
                {
                    name: '取消绑定公众号',
                    fun: this.consolebindpOPenId,
                    isShow:(row)=>{
                        return this.btnexist('scheduleBasicDriverConsolebind') && row.pOpendId != ''
                    }
                }
            ]
            this.operateList = l
        },
        // 设置行内元素样式
        setRowStyle({ row }) {
            if (row.sState == '0') {
                return { color: '#FF8888' }
            }
        },
        getlist() {
            let url = '/base/driver/queryPage'
            let option = {
                ...this.formInline,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize
            }
            this.$http.post(url, option).then(({ detail }) => {
                let dataProp = ['birth','dlIssueT','dlexpireT','eDate','rDate']
                //let exInfo = ['contractExT','cwaNo','driverId']
                let list = detail.list.map(item=>{
                    item.carPlateNums = item.vehicleInfos?item.vehicleInfos.map(item=>item.plateNum).join(','):''
                    dataProp.forEach(one=>{
                        item.driverInfo[one] = item.driverInfo[one]?item.driverInfo[one].split(' ')[0]:''
                    })
                    return {
                        ...item,
                        ...item.sysOrg,
                        ...item.driverInfo,
                        ...item.driverExInfo
                    }
                })
                console.log(list)
                this.lists = list
                this.total = detail.total
            })
        },
        // 所属机构
        addInstitutions(data) {
            console.log(data)
            this.formInline.orgId = data.orgId
            this.formInline.orgNa = data.orgNa
        },
        // 组织
        selectInstitutions() {
            this.$refs.institutions.init()
        },
        // 查询表格
        oncheck() {
            this.pageIndex = 1
            this.getlist()
            console.log('更新')
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.formInline.vehicleId = ''
            this.formInline.routId = ''
            this.formInline.orgId = ''
            this.oncheck()
        },
        // 导出
        onexport() {
            console.log('导出')
        },
        // 所属线路
        // addInstitutions(data) {
        //     console.log(data)
        //     this.formInline.routId = data.id
        //     this.formInline.routName = data.text
        // },
        // // 组织线路
        // selectInstitutions() {
        //     this.$refs.institutions.init()
        // },
        // 所属车辆
        addcar() {
            this.$refs.dialog.init()
        },
        checkCar(row) {
            this.formInline.vehicleId = row.vehicleId
            this.formInline.vehicleName = row.vehicleNo
        },
        // 批量导入
        batchimport() {},

        selectchange(selection) {
            console.log(selection)
            this.batchdeleteList = selection
        },
        selectallchange(selection) {
            console.log(selection)
            this.batchdeleteList = selection
        },

        // 分页
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`)
            this.pageSize = val
            this.getlist()
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`)
            this.pageIndex = val
            this.getlist()
        },
        add() {
            this.$refs.addUpdate.init()
        },
        update(row) {
            let option = {
                ...row
            }
            this.$refs.addUpdate.init(option, 'update')
        },
        deleteRow(row) {
            var url = '/base/driver/del'
            var option = {
                driverId : row.driverId,
            }
            this.$confirm('是否删除该司机?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(url, option).then(() => {
                    this.getlist()
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    })
                })
            })
        },
        pushShift(row) {
            let url = "/base/driver/pushShift"
            let option = {
                driverId: row.driverId,
                driverNa: row.cName
            }
            this.$http.post(url, option).then(() => {
                this.$message({
                    type: 'success',
                    message: '班次推送成功!'
                })
            })
        },
        consolebindpOPenId(row) {
            let url = "/base/driver/consolebindpOPenId"
            let option = {
                phone: row.phone
            }
            this.$http.post(url, option).then(() => {
                this.$message({
                    type: 'success',
                    message: '取消绑定司机公众号OpenId成功!'
                })
                this.getlist()
            })
        },
        //导出
        exportList() {
            let url = '/base/driver/exportDriver'
            let option = {
                ...this.formInline,
            }
            this.$http
                .post(url, option, { type: 'format', isExcel: true })
                .then(res => {
                    if (res.status == 200) {
                        this.$util.funDownload(res.data, '司机信息')
                    } else {
						this.$message({
							message: "导出失败",
							type: "warning",
						});
					}
                })
        },
        //导入
        importFun() {
            this.$refs.jyImport.init()
        },
        downloadTemplate() {
            let url = '/base/driver/downloadTemplate'
            this.$http
                .post(url, {}, { type: 'format', isExcel: true })
                .then(res => {
                    this.$util.funDownload(res.data, '司机模板')
                })
        },
        getElxData(data) {
            console.log(data.detail)
            this.importId = data.detail.importId
            //let url = '/baseinfodriver/importDriverCheck'
            let url = '/base/driver/importDriverCheck'
            let params = {
                importId: data.detail.importId,
            }
            this.$http.post(url, params).then(data => {
                let { detail } = data
                this.listError = detail.listError
                this.correctCount = detail.correctCount
                this.errorCount = detail.errorCount
            })
        },
        confirm() {
            let params = {
                importId: this.importId,
            }
            let url = "/base/driver/importDriverData"
            this.$http.post(url, params).then(
                data => {
                    if (data.code == 0) {
                        this.importStatus = 1
                        this.oncheck()
                    }
                },
                () => {
                    this.importStatus = -1
                }
            )
        },
    }
}

</script>
<style lang="scss" scoped="driverInformation">
</style>
<style lang="scss">
</style>
